import SideBar from "../../components/navigation/SideBar";
import { useNavigate } from "react-router-dom";
import { useCreateKnowbaseMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import { useState } from "react";
import { useCoach } from "../../useCoach";

function CreateKnowBase(){
  const navigate = useNavigate();
  const { coach } = useCoach();
  const [CreateKnowBase] = useCreateKnowbaseMutation();
  const [selectedCategory, setSelectedCategory] = useState({
    "id": 1,
    "value": "Training",
    "label": "Training"
  })
  const onHandleCategoryChange = (e)=> {
    setSelectedCategory(e);
  }
  const [selectedLevel, setSelectedLevel] = useState({
    "id": 1,
    "value": "Beginner",
    "label": "Beginner"
  })
  const onHandleLevelChange = (e)=> {
    setSelectedLevel(e);
  }

  const meta = {
      object: "Question",
      action: "Create new question",
      description: "Create a new question record.",
    };
  
  let config = [
    {
      id:'title_en',
      label: 'Enlgish Title',
      type: 'shortText',
      placeholder: 'How to do...?',
      size: 2
    },
    {
      id:'title_es',
      label: 'Spanish Title',
      type: 'shortText',
      placeholder: 'How to do...?',
      size: 2
    },
    {
      id:'category',
      label: 'Category',
      type: 'dropdown',
      placeholder: 'Choose category',
      options: [
        { id: 1, value: 'Training', label: 'Training' },
        { id: 2, value: 'Nutrition', label: 'Nutrition' },
        { id: 3, value: 'Rest', label: 'Rest' },
        { id: 4, value: 'Other', label: 'Other' },
      ],
      size: 2,
      onChange: onHandleCategoryChange,
    },
    {
      id:'level',
      label: 'Level',
      type: 'dropdown',
      placeholder: 'Choose level',
      options: [
        { id: 1, value: 'Beginner', label: 'Beginner' },
        { id: 2, value: 'Intermediate', label: 'Intermediate' },
        { id: 3, value: 'Advanced', label: 'Advanced' },
        { id: 4, value: 'Pro', label: 'Pro' },
      ],
      size: 2,
      onChange: onHandleLevelChange,
    },
    {
      id:'video_en',
      label: 'Link to video in English',
      type: 'shortText',
      placeholder: 'Video tutorial link',
      size: 2
    },
    {
      id:'video_es',
      label: 'Link to video in Spanish',
      type: 'shortText',
      placeholder: 'Video tutorial link',
      size: 2
    },
    {
      id:'body_en',
      label: 'Enlgish Body',
      type: 'richtext',
    },
    {
      id:'body_es',
      label: 'Spanish Body',
      type: 'richtext',
    },
  ];
  
  const handleCreate = (e) => {
    e.preventDefault();
  
    // Configurar el valor del campo 'sex' con el estado seleccionado
    e.target['category'] = selectedCategory;
    e.target['level'] = selectedLevel;
    // Preparar el objeto 'submitData' con los datos del formulario
    const submitData = config.reduce((o, input) => {
      // Si el tipo del campo es checkbox, obtener su estado 'checked'
      if (input.type === 'check') {
        const checkbox = e.target.elements[input.id];
        return {
          ...o,
          [input.id]: checkbox ? checkbox.checked : false,
        };
      }
  
      // Para otros tipos de campos, usar el valor
      return {
        ...o,
        [input.id]: e.target[input.id]?.value,
      };
    }, {});
    submitData.coach = coach.id;
    
    CreateKnowBase(submitData);
    navigate("/knowbase");
  };
  

  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <StandardForm meta={meta} config={config} onSubmit={handleCreate}/>
        </div>
      </main>
    </div>
    </>
  );
}

export default CreateKnowBase;