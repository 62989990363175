import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default function RichTextEditor({ id, label, initialContent, onChange }) {
  const editorRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const quill = new Quill(containerRef.current, {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // Formatting options
          [{ list: 'ordered' }, { list: 'bullet' }], // Ordered and unordered lists
          ['image', 'link'], // Insert options
        ],
      },
    });

    // Set initial content
    quill.clipboard.dangerouslyPasteHTML(initialContent || '');

    // Synchronize changes with hidden input
    quill.on('text-change', () => {
      const html = containerRef.current.querySelector('.ql-editor').innerHTML;
      editorRef.current.value = html;
      if (onChange) onChange(html); // Trigger external onChange if provided
    });
  }, [initialContent, onChange]);

  return (
    <div className="sm:col-span-3 pb-10">
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div ref={containerRef} className="quill-container" />
      <input
        type="hidden"
        id={id}
        name={id}
        ref={editorRef}
        defaultValue={initialContent || ''}
      />
    </div>
  );
}
