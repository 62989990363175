function TextInput ({label, id, placeholder,size, value, disabled = false, required = false}){
  return (
    <div className={`sm:col-span-${size}`}>
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
        {label}{required && <span className="text-red-500"> *</span>}
      </label>
      <div className="mt-2">
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
          <input
            type="text"
            name={id}
            id={id}
            defaultValue={value}
            {...(disabled ? { disabled: true } : {})}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={placeholder}
          />
        </div>
      </div>
    </div>
  );
}

export default TextInput;
