import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const knowbaseApi = createApi({
  reducerPath: 'knowbase',
  tagTypes:['Knowbase'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchAllKnowbase: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Knowbase'}];
        },
        query: (coach) => {
          return {
            url:'/faq',
            params:{
              coach:"eq."+coach,
              order:"created_at.asc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createKnowbase: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Knowbase'}];
        },
        query: (data) => {
          return {
            url:'/faq',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateKnowbase: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Knowbase'}];
        },
        query: ({id:knowbaseId,submitData}) => {
          return {
            url:'/faq',
            params:{
              id:"eq."+knowbaseId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchKnowbase: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Knowbase'}];
        },
        query: (knowbaseId) => {
          return {
            url:'/faq',
            params:{
              id:"eq."+knowbaseId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      deleteKnowbase: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Knowbase'}];
        },
        query: (id) => {
          return {
            url:'/faq',
            params:{
              id:"eq."+id
            },
            
            method: 'DELETE',
            headers: authHeaders
          }
        }
      }),
    }
  }
});

export const { useFetchAllKnowbaseQuery, useCreateKnowbaseMutation, 
  useUpdateKnowbaseMutation, useFetchKnowbaseQuery, useDeleteKnowbaseMutation
} = knowbaseApi;
export { knowbaseApi };