import SideBar from "../../components/navigation/SideBar";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchAthleteQuery, useFetchCoachQuery, useUpdateAthleteMutation, useUpdateCoachMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import { useState, useEffect } from "react";

function CoachForm(){
  let { id } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading} = useFetchCoachQuery(id);
  const [editCoach] = useUpdateCoachMutation();
  const [selectedRole, setSelectedRole] = useState({
    "id": 1,
    "value": "coach",
    "label": "Coach"
  })
  const onHandleRoleChange = (e)=> {
    setSelectedRole(e);
  }

  useEffect(() => {
    if (data && data.length > 0) {
      let coach = data[0];
      let valueOfRole = coach.role;
      let valueOfRoleObject = {};
      if (valueOfRole === "coach") {
        valueOfRoleObject = {
          id: 1,
          value: "coach",
          label: "Coach"
        };
      } else {
        valueOfRoleObject = {
          id: 2,
          value: "admin",
          label: "Admin"
        };
      }
      setSelectedRole(valueOfRoleObject);
    }
  }, [data]);

  let config = [
    {
      id:'name',
      label: 'Name',
      type: 'shortText',
      placeholder: 'John Doe',
      size: 2
    },
    {
      id:'email',
      label: 'Email',
      type: 'shortText',
      placeholder: 'johndoe@gmail.com',
      size: 2
    },
    {
      id:'role',
      label: 'Role',
      type: 'dropdown',
      placeholder: 'Choose role',
      options: [
        { id: 1, value: 'coach', label: 'Coach' },
        { id: 2, value: 'admin', label: 'Admin' },
      ],
      size: 2,
      onChange: onHandleRoleChange,
    },
    {
      id:'active',
      label: 'Coach Active',
      type: 'check',
      placeholder: '',
      size: '2'
    },
  ];

  const handleEdit = (e) => {
    e.preventDefault();
  
    // Obtener el valor de "active" basado en si está marcado o no
    const isActive = e.target.elements["active"]?.checked ? "true" : "false";
  
    // Generar los datos para enviar
    const submitData = config.reduce((o, input) => {
      let value;
      if (input.id === "role") {
        // Para "role", usamos el estado seleccionado
        value = selectedRole.value;
      } else if (input.id === "active") {
        // Para "active", usamos el valor calculado
        value = isActive;
      } else {
        // Para otros campos, usamos su valor directamente
        value = e.target.elements[input.id]?.value || "";
      }
      return { ...o, [input.id]: value };
    }, {});
    // Llamar a la mutación y redirigir
    editCoach({ id, submitData }).then(() => {
      navigate("/coaches");
    });
  };

  let meta;
  let basicDetailsForm;
  if (isLoading) {
    basicDetailsForm = <div> Loading</div>;
  } else if (error) {
    basicDetailsForm = <div> Errors</div>;
  } else {
    let coach = {...data[0]};
    if(coach.role ==="coach"){
      coach.role = {
        "id": 1,
        "value": "coach",
        "label": "Coach"
      }
    }else{
      coach.role = {
        "id": 2,
        "value": "admin",
        "label": "Admin"
      }
    }
    meta = {
      object: "Coach",
      action: "Edit details of " + coach.name,
      description: "Edit details of the coach.",
    };
    basicDetailsForm = <StandardForm meta={meta} config={config} data={coach} onSubmit={handleEdit} />;
  }

  return (
    <div>
      <SideBar />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {basicDetailsForm}
        </div>
      </main>
    </div>
  );
}

export default CoachForm;
