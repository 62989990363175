import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import {
  ArrowUturnLeftIcon,
} from '@heroicons/react/24/outline';
import { NavLink, useNavigate } from 'react-router-dom';
import TextInput from './inputs/TextInput';
import TextAreaInput from './inputs/TextAreaInput';
import DropDown from './inputs/DropDown';
import DateInput from './inputs/DateInput';
import CheckInput from './inputs/CheckInput';
import UnitInput from './inputs/UnitInput';
import RichTextEditor from './inputs/RichTextEditor';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function StandardForm({breadcrumb = true, meta, data, config, colsForm, onSubmit}) {
  const navigate = useNavigate();
 
  var renderedInputs;
  if(data === undefined){
    renderedInputs = config.map((input) => {
      switch(input.type) {
        case 'shortText':
          return <TextInput key={input.id} id={input.id}  label={input.label} placeholder={input.placeholder} size={input.size}/>;
        case 'text':
          return <TextAreaInput key={input.id} id={input.id} label={input.label} placeholder={input.placeholder} size={input.size}/>;
        case 'date':
          return <DateInput key={input.id} id={input.id}  label={input.label} placeholder={input.placeholder} size={input.size}/>;
        case 'check':
          return <CheckInput key={input.id} id={input.id}  label={input.label} value={false} size={input.size}/>;
        case 'richtext':
          return <RichTextEditor key={input.id} id={input.id}  label={input.label} initialContent=""/>;
        case 'unit' :
          return <UnitInput key={input.id} id={input.id} label={input.label} unit={input.unit} size={input.size}/>
        case 'dropdown': return (
          <div key={input.id} className={`sm:col-span-${input.size}`}>
            <label htmlFor={input.id} className="block text-sm font-medium leading-6 text-gray-900">
              {input.label}
            </label>
            <DropDown label={input.label} key={input.id} id={input.id} options={input.options} onChange={input.onChange}/>
          </div> );
        default:
          return''
      }
    });
  }
  else{
    renderedInputs = config.map((input) => {
      switch(input.type) {
        case 'shortText':
          return <TextInput key={input.id} id={input.id} value={data[input.id]} label={input.label} placeholder={input.placeholder} size={input.size}/>;
        case 'text':
          return <TextAreaInput key={input.id} id={input.id} value={data[input.id]} label={input.label} placeholder={input.placeholder} size={input.size}/>;
        case 'date':
          return <DateInput key={input.id} id={input.id} value={data[input.id]} label={input.label} placeholder={input.placeholder} size={input.size}/>;
        case 'check':
          return <CheckInput key={input.id} value={data[input.id]} id={input.id}  label={input.label}  size={input.size}/>;
        case 'richtext':
          return <RichTextEditor key={input.id} id={input.id}  label={input.label} initialContent={data[input.id]}/>;
        case 'unit' :
          return <UnitInput key={input.id} id={input.id} value={data[input.id]} label={input.label} unit={input.unit} size={input.size}/>
        case 'dropdown': 
          return (
            <div key={input.id} className={`sm:col-span-${input.size}`}>
              <label htmlFor={input.id} className="block text-sm font-medium leading-6 text-gray-900">
                {input.label}
              </label>
              <DropDown valueGiven={data[input.id]} label={input.label} key={input.id} id={input.id} options={input.options} onChange={input.onChange}/>
            </div> 
          );
        default:
          return''
      }
    });
  }
  
  return (
    <>
    {(breadcrumb && <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <NavLink onClick={(e)=>{
                  e.preventDefault();
                  navigate(-1)
            }} className="text-gray-400 hover:text-gray-500">
              <ArrowUturnLeftIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            </NavLink>
          </div>
        </li>
        <li >
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <NavLink
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {meta.action}
              </NavLink>
            </div>
          </li>
      </ol>
    </nav>)}
    <br/>
    <form onSubmit={onSubmit}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">{meta.object}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {meta.description}
          </p>
          
          <div className={classNames(
              colsForm ? 'sm:grid-cols-'+colsForm : 'sm:grid-cols-6',
              'mt-10 grid grid-cols-1 gap-x-6 gap-y-8'
            )}>
            {renderedInputs}
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button onClick={(e)=>{
                  e.preventDefault();
                  navigate(-1)}}
          type="button" className="text-sm font-semibold leading-6 text-gray-900">
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
    </>
  )
}
