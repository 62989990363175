
import { useFetchDeadliftAntropometryByAthleteIdQuery } from "../../store";
import TableCard from "../tables/TableCard";
import moment from "moment/moment";

export default function DeadliftAntropometryTable({athlete}) {
  const config = [
    {
      key: 'Date',
      label: 'Date',
      render: (pr) => moment(pr.created_at).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'headless_trunk_length',
      label: 'Headless Trunk Length',
      render: (pr) => pr.headless_trunk_length+' cm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'arm_length',
      label: 'Arm Length',
      render: (pr) => pr.arm_length+' cm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'trunk_length',
      label: 'Trunk length',
      render: (pr) => pr.trunk_length+' cm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'deadlift_variante',
      label: 'Recommended form of Deadlift',
      render: (pr) => recommendDeadlift(pr),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    }
  ];
  const keyFn = (record) => {
    return record.id;
  };

  const recommendDeadlift = (measures) => {
    const lower_body_length = measures.headless_trunk_length-measures.trunk_length;
    const NX = measures.trunk_length/measures.arm_length;
    const NY = measures.trunk_length/lower_body_length;
    if(NX < 0.82 && NY < 0.55){
      return "Standard deadlift"
    }
    else if (NX >= 0.82 && NY >= 0.55){
      return "Sumo deadlift"
    }
  }

  const { data, error, isLoading} = useFetchDeadliftAntropometryByAthleteIdQuery(athlete.id); 
  let table;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    table = <TableCard data={data} config={config} keyFn={keyFn} />;
  }
  return (
    <div>
        {table}
    </div>
  );
}