import React from 'react';
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BeakerIcon } from '@heroicons/react/24/outline';
import TextInput from '../forms/inputs/TextInput';
import TextAreaInput from '../forms/inputs/TextAreaInput';
import DateInput from '../forms/inputs/DateInput';
import { useParams } from 'react-router-dom';
import { useCreateDietNeedMutation } from '../../store';

export default function NutritionNeedsRegistrationModal({ data, onClose }) {
  const cancelButtonRef = useRef(null);
  const { id } = useParams();
  const [createDietNeeds] = useCreateDietNeedMutation();
  const handleRegistration = (event)=>{
    event.preventDefault();
    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());
    const submitData ={
      ...formValues,
      athlete: id,
      carbs: data.carbGramsKg,
      protein: data.protGramsKg,
      fat: data.lipGramsKg,
      weight: data.weight,
      kcal: data.kcal,
    }
    createDietNeeds(submitData);
    onClose(true);
  };

  const nutrition_requirements = [
    {name: "Carbohidrates"},
    {name: "Proteins"},
    {name: "Lipids"},
    {name: "KCal"},
  ];

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="fixed top-0 left-0 w-full h-full z-50 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="flex min-h-screen items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden w-full md:w-3/4 lg:w-1/2 bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 rounded-lg">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                <BeakerIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  Register dietary requirements
                </Dialog.Title>
                <div className="mt-2">
                  <form onSubmit={handleRegistration}>
                    <div className="space-y-12">
                      <div className="border-b border-gray-900/10 pb-12">
                        <div className="sm:grid-cols-6 mt-10 grid grid-cols-1 gap-x-6 gap-y-8">
                          <TextAreaInput key="guidelines" id="guidelines" label="Guidelines description" size="full" />
                          <TextAreaInput key="supplements" id="supplements" label="Supplements protocol" size="full" />
                          <TextAreaInput key="objectives" id="objectives" label="Objectives description" size="full" />
                          <TextInput key="water" id="water" label="Water" placeholder="Daily water intake" size={2} required={true}/>
                          <TextInput key="neat" id="neat" label="Neat" placeholder="Daily Steps" size={2} required={true}/>
                          <DateInput key="from" id="from"  label="From" placeholder="From" size={2} required={true}/>
                          <DateInput key="to" id="to"  label="To" placeholder="To" size={2} required={true}/>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-12">
                      <div className="border-b border-gray-900/10 pb-12 flex items-center justify-center">
                        <table className="min-w-min divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                Macros
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Daily Average
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                g/kg body weight
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {nutrition_requirements.map((req) => (
                              <tr key={req.name}>
                                <td className="whitespace-nowrap py-4 text-left pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                  {req.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {req.name === "KCal" ? data.kcal : ""}
                                  {req.name === "Carbohidrates" ? data.dailyCarbs : ""}
                                  {req.name === "Proteins" ? data.dailyProtein : ""}
                                  {req.name === "Lipids" ? data.dailyLipids : ""}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {req.name === "Carbohidrates" ? data.carbGramsKg : ""}
                                  {req.name === "Proteins" ? data.protGramsKg : ""}
                                  {req.name === "Lipids" ? data.lipGramsKg : ""}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                      <button
                        type="button"
                        onClick={onClose}
                        className="mr-2 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
