import SideBar from "../../components/navigation/SideBar";
import { useNavigate } from "react-router-dom";
import { useCreateAthleteMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import { useState } from "react";
import { useCoach } from "../../useCoach";

function CreateAthleteForm(){
  const navigate = useNavigate();
  const { coach } = useCoach();
  const [createAthlete] = useCreateAthleteMutation();
  const [selectedSex, setSelectedSex] = useState({
    "id": 1,
    "value": "Male",
    "label": "Male"
  })
  const onHandleSexChange = (e)=> {
    setSelectedSex(e);
  }

  const meta = {
      object: "Athlete",
      action: "Create new Athlete",
      description: "Create a new athlete record.",
    };
  
  let config = [
    {
      id:'firstname',
      label: 'Firstname',
      type: 'shortText',
      placeholder: 'John',
      size: 2
    },
    {
      id:'lastname',
      label: 'Lastname',
      type: 'shortText',
      placeholder: 'Doe',
      size: 2
    },
    {
      id:'email',
      label: 'Email',
      type: 'shortText',
      placeholder: 'johndoe@gmail.com',
      size: 2
    },
    {
      id:'sex',
      label: 'Sex',
      type: 'dropdown',
      placeholder: 'Choose sex',
      options: [
        { id: 1, value: 'Male', label: 'Male' },
        { id: 2, value: 'Female', label: 'Female' },
      ],
      size: 2,
      onChange: onHandleSexChange,
    },
    {
      id:'birthdate',
      label: 'Birthdate',
      type: 'date',
      placeholder: '',
      size: 2
    },
    {
      id:'phone_number',
      label: 'Phone Number',
      type: 'shortText',
      placeholder: '+45 60180641',
      size: 2
    },
    {
      id:'some_profile',
      label: 'Social Media Profile',
      type: 'text',
      placeholder: 'Write in different lines each some profile.',
      size: 'full'
    },
    {
      id:'active',
      label: 'Athlete Active',
      type: 'check',
      placeholder: '',
      size: '2'
    },
    {
      id: 'advanced_habits',
      label: 'Advanced habits monitorization',
      type: 'check',
      placeholder: '',
      size: '2'
    },
  ];
  
  const handleCreate = (e) => {
    e.preventDefault();
  
    // Configurar el valor del campo 'sex' con el estado seleccionado
    e.target['sex'] = selectedSex;
  
    // Preparar el objeto 'submitData' con los datos del formulario
    const submitData = config.reduce((o, input) => {
      // Si el tipo del campo es checkbox, obtener su estado 'checked'
      if (input.type === 'check') {
        const checkbox = e.target.elements[input.id];
        return {
          ...o,
          [input.id]: checkbox ? checkbox.checked : false,
        };
      }
  
      // Para otros tipos de campos, usar el valor
      return {
        ...o,
        [input.id]: e.target[input.id]?.value,
      };
    }, {});
    submitData.coach = coach.id;
    
    createAthlete(submitData);
    navigate("/athletes");
  };
  

  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <StandardForm meta={meta} config={config} onSubmit={handleCreate}/>
        </div>
      </main>
    </div>
    </>
  );
}

export default CreateAthleteForm;