import SideBar from "../../components/navigation/SideBar";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchKnowbaseQuery, useUpdateKnowbaseMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import { useState, useEffect } from "react";

function EditKnowBase(){
  let { id } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading} = useFetchKnowbaseQuery(id);
  const [editKnowBase] = useUpdateKnowbaseMutation();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedLevel, setSelectedLevel] = useState();

  const onHandleCategoryChange = (e) => {
    setSelectedCategory(e);
  };
  const onHandleLevelChange = (e) => {
    setSelectedLevel(e);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      let faq = data[0];
      let valueOfCategory = faq.category;
      let valueOfCategoryObject = {};
      if (valueOfCategory === "Training") {
        valueOfCategoryObject = {
          id: 1,
          value: "Training",
          label: "Training"
        };
      } else if (valueOfCategory === "Nutrition") {
        valueOfCategoryObject = {
          id: 2,
          value: "Nutrition",
          label: "Nutrition"
        };
      }else if (valueOfCategory === "Rest") {
        valueOfCategoryObject = {
          id: 3,
          value: "Rest",
          label: "Rest"
        };
      } else {
        valueOfCategoryObject = {
          id: 4,
          value: "Other",
          label: "Other"
        };
      }
      let valueOfLevel = faq.level;
      let valueOfLevelObject = {};
      if (valueOfLevel === "Beginner") {
        valueOfLevelObject = {
          id: 1,
          value: "Beginner",
          label: "Beginner"
        };
      } else if(valueOfLevel === "Intermediate") {
        valueOfLevelObject = {
          id: 2,
          value: "Intermediate",
          label: "Intermediate"
        };
      }else if(valueOfLevel === "Advanced") {
        valueOfLevelObject = {
          id: 3,
          value: "Advanced",
          label: "Advanced"
        };
      } else {
        valueOfLevelObject = {
          id: 4,
          value: "Pro",
          label: "Pro"
        };
      }
      setSelectedCategory(valueOfCategoryObject);
      setSelectedLevel(valueOfLevelObject);
    }
  }, [data]);

  let config = [
    {
      id:'title_en',
      label: 'Enlgish Title',
      type: 'shortText',
      placeholder: 'How to do...?',
      size: 2
    },
    {
      id:'title_es',
      label: 'Spanish Title',
      type: 'shortText',
      placeholder: 'How to do...?',
      size: 2
    },
    {
      id:'category',
      label: 'Category',
      type: 'dropdown',
      placeholder: 'Choose category',
      options: [
        { id: 1, value: 'Training', label: 'Training' },
        { id: 2, value: 'Nutrition', label: 'Nutrition' },
        { id: 3, value: 'Rest', label: 'Rest' },
        { id: 4, value: 'Other', label: 'Other' },
      ],
      size: 2,
      onChange: onHandleCategoryChange,
    },
    {
      id:'level',
      label: 'Level',
      type: 'dropdown',
      placeholder: 'Choose level',
      options: [
        { id: 1, value: 'Beginner', label: 'Beginner' },
        { id: 2, value: 'Intermediate', label: 'Intermediate' },
        { id: 3, value: 'Advanced', label: 'Advanced' },
        { id: 4, value: 'Pro', label: 'Pro' },
      ],
      size: 2,
      onChange: onHandleLevelChange,
    },
    {
      id:'video_en',
      label: 'Link to video in English',
      type: 'shortText',
      placeholder: 'Video tutorial link',
      size: 2
    },
    {
      id:'video_es',
      label: 'Link to video in Spanish',
      type: 'shortText',
      placeholder: 'Video tutorial link',
      size: 2
    },
    {
      id:'body_en',
      label: 'Enlgish Body',
      type: 'richtext',
    },
    {
      id:'body_es',
      label: 'Spanish Body',
      type: 'richtext',
    },
  ];

  const handleEdit = (e) => {
    e.preventDefault();
  
    // Configurar el valor del campo 'sex' con el estado seleccionado
    e.target['category'] = selectedCategory;
    e.target['level'] = selectedLevel;
    // Preparar el objeto 'submitData' con los datos del formulario
    const submitData = config.reduce((o, input) => {
      // Si el tipo del campo es checkbox, obtener su estado 'checked'
      if (input.type === 'check') {
        const checkbox = e.target.elements[input.id];
        return {
          ...o,
          [input.id]: checkbox ? checkbox.checked : false,
        };
      }
  
      // Para otros tipos de campos, usar el valor
      return {
        ...o,
        [input.id]: e.target[input.id]?.value,
      };
    }, {});
    
    editKnowBase({ id, submitData });
    navigate("/knowbase");
  };
  

  let meta;
  let basicDetailsForm;
  if (isLoading) {
    basicDetailsForm = <div> Loading</div>;
  } else if (error) {
    basicDetailsForm = <div> Errors</div>;
  } else {
    let faq = {...data[0]};
    if(faq.category ==="Training"){
      faq.category = {
        "id": 1,
        "value": "Training",
        "label": "Training"
      }
    } else if(faq.category ==="Nutrition"){
      faq.category = {
        "id": 2,
        "value": "Nutrition",
        "label": "Nutrition"
      }
    }else if(faq.category ==="Rest"){
      faq.category = {
        "id": 3,
        "value": "Rest",
        "label": "Rest"
      }
    }else{
      faq.category = {
        "id": 4,
        "value": "Other",
        "label": "Other"
      }
    }
    if(faq.level ==="Beginner"){
      faq.level = {
        "id": 1,
        "value": "Beginner",
        "label": "Beginner"
      }
    }else if(faq.level ==="Intermediate"){
      faq.level = {
        "id": 2,
        "value": "Intermediate",
        "label": "Intermediate"
      }
    }else if(faq.level ==="Advanced"){
      faq.level = {
        "id": 3,
        "value": "Advanced",
        "label": "Advanced"
      }
    }else{
      faq.level = {
        "id": 4,
        "value": "Pro",
        "label": "Pro"
      }
    }
    meta = {
      object: "Question",
      action: "Edit details of question id:" + faq.id,
      description: "Edit details of the question.",
    };
    basicDetailsForm = <StandardForm meta={meta} config={config} data={faq} onSubmit={handleEdit} />;
  }

  return (
    <div>
      <SideBar />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {basicDetailsForm}
        </div>
      </main>
    </div>
  );
}

export default EditKnowBase;
