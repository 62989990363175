import React from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);


const WeightChart = ({ data }) => {
  // Combina los datos de los diferentes datasets para encontrar el rango
  const allWeights = data.flatMap(journal => [
    journal.weight,
    journal.weight_midday,
    journal.weight_night
  ]).filter(weight => weight !== null && weight !== undefined); // Filtrar datos nulos o indefinidos

  // Calcular el valor mínimo y máximo, añadiendo un margen de 10
  const minWeight = Math.min(...allWeights) - 1;
  const maxWeight = Math.max(...allWeights) + 1;

  const chartData = {
    labels: data.map(journal => moment(journal.date).format('DD/MM/YYYY')),
    datasets: [
      {
        label: 'Weight in the morning(kg)',
        data: data.map(journal => {
          switch (journal.weight) {
            default: return journal.weight;
          }
        }),
        fill: false,
        borderColor: 'rgb(255, 140, 0)',
        tension: 0.1,
        cubicInterpolationMode: 'monotone'
      },
      {
        label: 'Weight at Midday (kg)',
        data: data.map(journal => {
          switch (journal.weight_midday) {
            default: return journal.weight_midday;
          }
        }),
        fill: false,
        borderColor: 'rgb(255, 0, 0)',
        tension: 0.1,
        cubicInterpolationMode: 'monotone'
      },
      {
        label: 'Weight at Night (kg)',
        data: data.map(journal => {
          switch (journal.weight_night) {
            default: return journal.weight_night;
          }
        }),
        fill: false,
        borderColor: 'rgb(0, 255, 0)',
        tension: 0.1,
        cubicInterpolationMode: 'monotone'
      },
    ],
  };

  const options = {
    spanGaps: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Weigh (kg)',
        },
        min: minWeight,
        max: maxWeight,
      },
      x: {
        title: {
          display: true,
          text: 'Date',
        }
      }
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <Line data={chartData} options={options} />
    </div>
  );
};

export default WeightChart;
