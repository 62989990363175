import AntropometryForm from "../../../components/antropometry/AntropometryForm";
import { useParams } from "react-router-dom";
import { useFetchAthleteQuery } from "../../../store";
import AntropometryTable from "../../../components/antropometry/AntropometryTable";
import AntropometryChart from "../../../components/antropometry/AntropometryChart";
import DeadliftAntropometryTable from "../../../components/antropometry/DeadliftAntropometryTable";

export default function AntropometryManager(){
  let table;
  let { id } = useParams();
  const { data, error, isLoading} = useFetchAthleteQuery(id);
  let athlete;
  let content;
  if (isLoading) {
    content = <div> Loading...</div>;
    return content;
  }
  if (error) {
    content = <div> Error: {error.message}</div>;
    return content;
  }
  else{
    athlete = data[0];
    content = <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="space-y-12">
          <div className="grid grid-cols-2 mt-4 sm:grid-cols-2 lg:grid-cols-2 gap-6">
            <div className="p-4 bg-white rounded-lg shadow-lg">
            <AntropometryChart athlete={athlete} />
            </div>
            <DeadliftAntropometryTable athlete={athlete}/> 
          </div>
        </div>
            
      </div>
      <div className="border-b border-gray-900/10 pb-12">
        <AntropometryTable athlete={athlete} />
      </div>
      <div className="pb-12">
        <AntropometryForm athlete={athlete} />
      </div>
    </div>
  }
  return (
    content
  )
}