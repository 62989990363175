import SideBar from "../../components/navigation/SideBar";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchAthleteQuery, useUpdateAthleteMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import { useState, useEffect } from "react";

function AthleteForm(){
  let { id } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading} = useFetchAthleteQuery(id);
  const [editAthlete] = useUpdateAthleteMutation();
  const [selectedSex, setSelectedSex] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState();

  const onHandleSexChange = (e) => {
    setSelectedSex(e);
  };
  const onHandleLanguageChange = (e) => {
    setSelectedLanguage(e);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      let athlete = data[0];
      let valueOfSex = athlete.sex;
      let valueOfSexObject = {};
      if (valueOfSex === "Male") {
        valueOfSexObject = {
          id: 1,
          value: "Male",
          label: "Male"
        };
      } else {
        valueOfSexObject = {
          id: 2,
          value: "Female",
          label: "Female"
        };
      }
      let valueOfLanguage = athlete.language;
      let valueOfLanguageObject = {};
      if (valueOfLanguage === "English") {
        valueOfLanguageObject = {
          id: 1,
          value: "English",
          label: "English"
        };
      } else {
        valueOfLanguageObject = {
          id: 2,
          value: "Spanish",
          label: "Spanish"
        };
      }
      setSelectedSex(valueOfSexObject);
      setSelectedLanguage(valueOfLanguageObject);
    }
  }, [data]);

  let config = [
    {
      id: 'firstname',
      label: 'Firstname',
      type: 'shortText',
      placeholder: 'John',
      size: 2
    },
    {
      id: 'lastname',
      label: 'Lastname',
      type: 'shortText',
      placeholder: 'Doe',
      size: 2
    },
    {
      id: 'email',
      label: 'Email',
      type: 'shortText',
      placeholder: 'johndoe@gmail.com',
      size: 2
    },
    {
      id: 'sex',
      label: 'Sex',
      type: 'dropdown',
      placeholder: 'Choose sex',
      options: [
        { id: 1, value: 'Male', label: 'Male' },
        { id: 2, value: 'Female', label: 'Female' },
      ],
      size: 2,
      onChange: onHandleSexChange,
    },
    {
      id: 'birthdate',
      label: 'Birthdate',
      type: 'date',
      placeholder: '',
      size: 2
    },
    {
      id: 'phone_number',
      label: 'Phone Number',
      type: 'shortText',
      placeholder: 'Example: +45 60180641',
      size: 2
    },
    {
      id: 'some_profile',
      label: 'Social Media Profile',
      type: 'text',
      placeholder: 'Write in different lines each some profile.',
      size: 'full'
    },
    {
      id: 'language',
      label: 'Language',
      type: 'dropdown',
      placeholder: 'Choose language',
      options: [
        { id: 1, value: 'English', label: 'English' },
        { id: 2, value: 'Spanish', label: 'Spanish' },
      ],
      size: 2,
      onChange: onHandleLanguageChange,
    },
    {
      id: 'active',
      label: 'Athlete Active',
      type: 'check',
      placeholder: '',
      size: '2'
    },
    {
      id: 'advanced_habits',
      label: 'Advanced habits monitorization',
      type: 'check',
      placeholder: '',
      size: '2'
    },
  ];

  const handleEdit = (e) => {
    e.preventDefault();
  
    // Configurar el valor del campo 'sex' con el estado seleccionado
    e.target['sex'] = selectedSex;
    e.target['language'] = selectedLanguage;
    // Preparar el objeto 'submitData' con los datos del formulario
    const submitData = config.reduce((o, input) => {
      // Si el tipo del campo es checkbox, obtener su estado 'checked'
      if (input.type === 'check') {
        const checkbox = e.target.elements[input.id];
        return {
          ...o,
          [input.id]: checkbox ? checkbox.checked : false,
        };
      }
  
      // Para otros tipos de campos, usar el valor
      return {
        ...o,
        [input.id]: e.target[input.id]?.value,
      };
    }, {});
    
    editAthlete({ id, submitData });
    navigate("/athletes");
  };
  

  let meta;
  let basicDetailsForm;
  if (isLoading) {
    basicDetailsForm = <div> Loading</div>;
  } else if (error) {
    basicDetailsForm = <div> Errors</div>;
  } else {
    let athlete = {...data[0]};
    if(athlete.sex ==="Male"){
      athlete.sex = {
        "id": 1,
        "value": "Male",
        "label": "Male"
      }
    }else{
      athlete.sex = {
        "id": 2,
        "value": "Female",
        "label": "Female"
      }
    }
    if(athlete.language ==="English"){
      athlete.language = {
        "id": 1,
        "value": "English",
        "label": "English"
      }
    }else{
      athlete.language = {
        "id": 2,
        "value": "Spanish",
        "label": "Spanish"
      }
    }
    meta = {
      object: "Athlete",
      action: "Edit details of " + athlete.firstname + " " + athlete.lastname,
      description: "Edit details of the athlete.",
    };
    basicDetailsForm = <StandardForm meta={meta} config={config} data={athlete} onSubmit={handleEdit} />;
  }

  return (
    <div>
      <SideBar />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {basicDetailsForm}
        </div>
      </main>
    </div>
  );
}

export default AthleteForm;
