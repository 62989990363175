import { useState, useEffect, } from "react";
import { useParams } from "react-router-dom";
import { useFetchAthleteQuery, useUpdateAthleteMutation } from "../../../store";

function NotesManager() {
  let { id } = useParams();
  const { data, error, isLoading, refetch } = useFetchAthleteQuery(id);
  const [note, setNote] = useState(""); // Estado local para controlar el textarea
  const [saveNote, { isLoading: isSaving, error: saveError }] = useUpdateAthleteMutation(); // Mutación de RTK Query

  let athlete;
  useEffect(() => {
    if (athlete?.note) {
      setNote(athlete.note);
    }
  }, [athlete?.note]); // Dependencia de `athlete.note` para asegurar que solo se actualice cuando cambie
  // Muestra el estado de carga
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Muestra errores de carga inicial
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Sincroniza el valor de `note` con los datos del atleta solo cuando el componente se monta
  athlete = data[0];

  // Solo establece el valor de `note` una vez, al montar el componente
  

  const handleSaveNote = async () => {
    try {
      let submitData = {note:note};
      await saveNote({
        id: athlete.id,
        submitData,
      }).unwrap(); // Guarda la nota y espera a que termine

      // Realiza el refetch para actualizar los datos desde la base de datos
      await refetch();
    } catch (err) {
      console.error("Error saving note:", err);
    }
  };

  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="space-y-12">
          <div className="grid grid-cols-2 mt-4 sm:grid-cols-2 lg:grid-cols-2 gap-6">
            {/* Nota actual */}
            <div className="p-4 bg-white rounded-lg shadow-lg">
              <p>
                <b>My notes about {athlete.firstname}:</b>
              </p>
              <textarea
                id="note"
                rows="6"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Write your note here..."
                value={note}
                onChange={(e) => setNote(e.target.value)} // Actualiza el estado local del textarea
              />
              <button
                onClick={handleSaveNote}
                disabled={isSaving}
                className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md shadow-md hover:bg-indigo-700 disabled:bg-gray-300"
              >
                {isSaving ? "Saving..." : "Save Note"}
              </button>
              {saveError && (
                <p className="mt-2 text-sm text-red-600">
                  Error saving note: {saveError.message || "Failed to save"}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotesManager;
