import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const deadliftAntropometryApi = createApi({
  reducerPath: 'deadlift_antropometry',
  tagTypes:['DeadliftAntropometry'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchDeadliftAntropometrys: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'DeadliftAntropometry'}];
        },
        query: () => {
          return {
            url:'/deadlift_antropometry',
            params:{
              order:"created_at.asc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createDeadliftAntropometry: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'DeadliftAntropometry'}];
        },
        query: (data) => {
          return {
            url:'/deadlift_antropometry',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateDeadliftAntropometry: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'DeadliftAntropometry'}];
        },
        query: ({id:deadlift_antropometryId,submitData}) => {
          return {
            url:'/deadlift_antropometry',
            params:{
              id:"eq."+deadlift_antropometryId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchDeadliftAntropometry: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'DeadliftAntropometry'}];
        },
        query: (deadlift_antropometryId) => {
          return {
            url:'/deadlift_antropometry',
            params:{
              id:"eq."+deadlift_antropometryId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchDeadliftAntropometryByAthleteId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'DeadliftAntropometry'}];
        },
        query: (athleteId) => {
          return {
            url:'/deadlift_antropometry',
            params:{
              athlete:"eq."+athleteId,
              order:"created_at.desc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchDeadliftAntropometrysQuery, useCreateDeadliftAntropometryMutation, useUpdateDeadliftAntropometryMutation, useFetchDeadliftAntropometryQuery, 
  useFetchDeadliftAntropometryByAthleteIdQuery, 
  } = deadliftAntropometryApi;
export { deadliftAntropometryApi };