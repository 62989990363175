import SideBar from "../../components/navigation/SideBar";
import TableCard from "../../components/tables/TableCard";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchExerciseQuery, useUpdateExerciseMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import WorkoutListWithExercise from "../../components/workout/WorkoutListWithExercise";

function EditExerciseForm(){
  let { id } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading} = useFetchExerciseQuery(id);
  const [editExercise, results] = useUpdateExerciseMutation();
  const config = [
    {
      id:'name',
      label: 'Exercise name',
      type: 'shortText',
      placeholder: 'Bench Press',
      size: 2
    },
    {
      id:'video_tutorial_en',
      label: 'Link to video in english',
      type: 'shortText',
      placeholder: 'Video tutorial link',
      size: 2
    },
    {
      id:'video_tutorial_es',
      label: 'Link to video in spanish',
      type: 'shortText',
      placeholder: 'Video tutorial link',
      size: 2
    },
    {
      id:'description',
      label: 'Exercise Description',
      type: 'text',
      placeholder: 'Write how to conduct the exercise.',
      size: 'full'
    },
  ];
  const handleEdit = (e) =>{
    e.preventDefault();
    
    const submitData = config.reduce((o, input) => ({ ...o, [input.id]: e.target[input.id].value}), {});
    editExercise({id, submitData});
    navigate("/exercises");
  };
  let meta;
  let basicDetailsForm;
  if (isLoading){
    basicDetailsForm = <div> Loading</div>
  } else if (error){
    basicDetailsForm = <div> Errors</div>
  } else {
    let exercise = data[0];
    meta = {
      object: "Exercise",
      action: "Edit details of "+exercise.name+".",
      description: "Edit details of the exercise.",
    };
    basicDetailsForm = <StandardForm meta={meta} config={config} colsForm={1} data={exercise} onSubmit={handleEdit}/>;
  }

  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 px-4 sm:px-6 lg:px-8">
          <div className="lg:col-span-2">
            {basicDetailsForm}
          </div>
          <div className="lg:col-span-1">
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <br/><br/>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Programs & Workout list</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Programs and Workouts with this exercise:
                </p>
                <div className="">
                  <WorkoutListWithExercise id={id}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    </>
  );
}

export default EditExerciseForm;