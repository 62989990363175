import SideBar from "../../components/navigation/SideBar";
import BasicActions from "../../components/headings/BasicActions";
import TableCard from "../../components/tables/TableCard";
import { NavLink, useNavigate } from "react-router-dom";
import { useDeleteKnowbaseMutation, useFetchAllKnowbaseQuery } from "../../store";
import { useCoach } from "../../useCoach";
import moment from "moment/moment";

function KnowBase(){
  const navigate = useNavigate();
  const { coach } = useCoach();
  const [deleteKnowbase, results] = useDeleteKnowbaseMutation();
  const handleDelete = (e,faq)=>{
    e.preventDefault();
    deleteKnowbase(faq);
  }
  const config = [
    {
      key: 'Id',
      label: 'Id',
      render: (faq) => faq.id,
      rowClassname: 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
      columnClassname: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      key: 'English Title',
      label: 'English Title',
      render: (faq) => faq.title_en,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Spanish Title',
      label: 'Spanish Title',
      render: (faq) => faq.title_es,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Category',
      label: 'Category',
      render: (faq) => faq.category,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Level',
      label: 'Level',
      render: (faq) => faq.level,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Registered',
      label: 'Registered',
      render: (faq) => moment(faq.created_at).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Edit',
      label: '',
      render: (faq) => <NavLink to={`/knowbase/${faq.id}`} className="text-indigo-600 hover:text-indigo-900">
        Edit<span className="sr-only">, {faq.title_en}</span>
        </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Delete',
      label: '',
      render: (faq) => <NavLink to="/" onClick={(e)=>handleDelete(e,faq.id)} className="text-indigo-600 hover:text-indigo-900">
        Delete<span className="sr-only">{faq.title_en}</span>
        </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];

  const handleNew = (event)=>{
    event.preventDefault();
    navigate("/knowbase/create");
  }

  const actions = [
    {label: 'New', onClick:handleNew, class: 'primary'},
  ]

  const keyFn = (program) => {
    return program.id;
  };

  const { data, error, isLoading} = useFetchAllKnowbaseQuery(coach.id);
  let table;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    table = <TableCard data={data} config={config} keyFn={keyFn} />;
  }
  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <BasicActions object="Knowledge Base" actions={actions}/>
          {table}
        </div>
      </main>
    </div>
    </>
  );
}

export default KnowBase;