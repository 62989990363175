import SideBar from "../../components/navigation/SideBar";
import { useNavigate } from "react-router-dom";
import { useCreateAthleteMutation, useCreateCoachMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import { useState } from "react";
import { useCoach } from "../../useCoach";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

function CreateCoachForm(){
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { coach } = useCoach();
  const [createCoach] = useCreateCoachMutation();
  const [selectedRole, setSelectedRole] = useState({
    "id": 1,
    "value": "coach",
    "label": "Coach"
  })
  const onHandleRoleChange = (e)=> {
    setSelectedRole(e);
  }

  const meta = {
      object: "Coach",
      action: "Create new Coach",
      description: "Create a new coach record.",
    };
  
  let config = [
    {
      id:'name',
      label: 'Name',
      type: 'shortText',
      placeholder: 'John Doe',
      size: 2
    },
    {
      id:'email',
      label: 'Email',
      type: 'shortText',
      placeholder: 'johndoe@gmail.com',
      size: 2
    },
    {
      id:'role',
      label: 'Role',
      type: 'dropdown',
      placeholder: 'Choose role',
      options: [
        { id: 1, value: 'coach', label: 'Coach' },
        { id: 2, value: 'admin', label: 'Admin' },
      ],
      size: 2,
      onChange: onHandleRoleChange,
    }
  ];
  
  const handleCreate = async (e) => {
    e.preventDefault();
  
  
    const submitData = config.reduce((o, input) => {
      let value;
      if (input.id === "role") {
        value = selectedRole.value;
      } else {
        value = e.target.elements[input.id]?.value || "";
      }
      return { ...o, [input.id]: value };
    }, {});
  
    try {
      // Paso 1: Crear el usuario en Auth0
      const token = await axios.post(
        'https://incredibilis4fitness.eu.auth0.com/oauth/token',
        {"client_id":"bZHEPc1SAn6uaMOJUxxmKm5OBcixscCE",
          "client_secret":"bGFUNhD_x98S76zmlyN8500bJp_zytPMDCsK8GsATbAd9qYGWycRzqw6YeECKBTv",
          "audience":"https://incredibilis4fitness.eu.auth0.com/api/v2/",
          "grant_type":"client_credentials"
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );
  
      const auth0User = {
        email: submitData.email, // Usamos el email del formulario
        name: submitData.name, // Opcional: Nombre del usuario
        connection: "Username-Password-Authentication", // O tu conexión configurada
        password: "TemporaryPassword123!", // Genera una contraseña temporal si es necesario
      };
      console.log(token);
      const auth0Response = await axios.post(
        'https://incredibilis4fitness.eu.auth0.com/api/v2/users',
        auth0User,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log("Usuario creado en Auth0:", auth0Response.data);
  
      // Paso 2: Crear el usuario en tu backend
      await createCoach(submitData);
  
      // Redirigir a la lista de coaches
      navigate("/coaches");
    } catch (error) {
      console.error("Error creando usuario:", error);
      // Muestra un mensaje de error al usuario si falla
      alert("Hubo un error al crear el usuario. Inténtalo de nuevo.");
    }
  };

  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <StandardForm meta={meta} config={config} onSubmit={handleCreate}/>
        </div>
      </main>
    </div>
    </>
  );
}

export default CreateCoachForm;