import { MenuItem } from "@headlessui/react";
import { useFetchWorkoutsWithExerciseQuery } from "../../store";

export default function WorkoutListWithExercise ({id}){
  const { data, error, isLoading} = useFetchWorkoutsWithExerciseQuery(id);
  let content;
  let totalPrograms;
  let totalWorkouts;
  if (isLoading){
    content = <div> Loading</div>
  } else if (error){
    content = <div> Errors</div>
  } else {
    let workouts;
    let workoutsGrouped;
    if (data.length > 0) {
      workouts = data.map(item => item.workout);
      workouts = workouts.sort((a, b) => a.order - b.order);
      const groupedByProgram = workouts.reduce((acc, item) => {
        let programName;
        if(item.program ==null){
          programName = "Not assigned to program";
        }else{
          programName = item.program.name;
        }
        if (!acc[programName]) {
          acc[programName] = {
            programName: programName,
            workouts: []
          };
        }
        acc[programName].workouts.push(item);
        return acc;
      }, {});
      workoutsGrouped = Object.values(groupedByProgram);
      totalPrograms = workoutsGrouped.length;
      totalWorkouts = workoutsGrouped.reduce((acc, group) => acc + group.workouts.length, 0);
      content = (
        <div className="">
          <p className="mt-1 text-sm leading-6 text-gray-600">
            This exercise is in {totalPrograms} programs and {totalWorkouts} workouts.
          </p>
          {workoutsGrouped.map((group, index) => (
            <div key={index} className="bg-white p-4 rounded shadow mb-4">
              <h2 className="text-xl font-bold mb-2">{group.programName}</h2>
              <ul className="list-disc pl-5">
                {group.workouts.map((workout, idx) => (
                  <li key={idx}>{workout.name}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      );
    }
    else{
      content =<p className="mt-1 text-sm leading-6 text-gray-600">
       0. ThisExercise is not assigned in any workout.
      </p>;
    }
  }
  return content;
}