import React from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);


const HabitsDiffcultyChart = ({ data }) => {
  const chartData = {
    labels: data.map(journal => moment(journal.date).format('DD/MM/YYYY')),
    datasets: [{
      label: 'Diet Difficulty',
      data: data.map(journal => {
        switch (journal.habits_difficulty) {
          case 'Terribly Bad': return 1;
          case 'More or less': return 2;
          case 'Almost perfect': return 3;
          case '100% Perfect': return 4;
          default: return null;
        }
      }),
      fill: false,
      borderColor: 'rgb(0, 191, 255)',
      tension: 0.1,
       cubicInterpolationMode: 'monotone'
    }],
  };

  const options = {
    spanGaps: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Habits Difficulty',
        },
        min: 1,
        max: 5,
        ticks: {
          callback: function(value, index, values) {
            switch (value) {
              case 1: return 'Terribly Bad';
              case 2: return 'More or Less';
              case 3: return 'Almost Perfect';
              case 4: return '100% Perfect';
              default: return '';
            }
          }
        }
      },
      x: {
        title: {
          display: true,
          text: 'Date',
        }
      }
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <Line data={chartData} options={options} />
    </div>
  );
};

export default HabitsDiffcultyChart;
